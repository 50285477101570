import { Injectable } from "@angular/core";
import Intercom from "@intercom/messenger-js-sdk";
import { JwtPayload, jwtDecode } from "jwt-decode";

interface Payload extends JwtPayload {
    name: string;
    email: string;
    intercom: {
        app_key: string;
        user_id: string;
        hmac: string;
    };
}

@Injectable({ providedIn: 'root' })
export class IntercomService {
    public initializeIntercom() {
        try {
            const token = localStorage.getItem('token');
            const jwt = jwtDecode<Payload>(token);

            Intercom({
                api_base: "https://api-iam.intercom.io",
                app_id: jwt.intercom.app_key,
                user_id: jwt.intercom.user_id,
                name: jwt.name,
                email: jwt.email,
                user_hash: jwt.intercom.hmac,
                user_type: "portal-user",
                module: "Management"
            });
        } catch (Error) {
            console.log(Error);
        }
    }
}
